
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinWizard from '@/mixins/mixinWizard';
import CommonProgress from "@/components/common/Progress.vue";
import WizardEmployee from "@/components/wizard/Employee.vue";

@Component({
  components: {
    CommonProgress,
    WizardEmployee,
  }
})
export default class Company extends Mixins(mixinWizard) {

}
