
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinWizard from '@/mixins/mixinWizard';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/employee/manager";
import CommonText from "@/components/common/Text.vue";
import CommonRadio from "@/components/common/Radio.vue";
import CommonDate from "@/components/common/Date.vue";

@Component({
  components: {
    CommonText,
    CommonRadio,
    CommonDate,
  }
})
export default class Main extends Mixins(mixinWizard) {
  get tmp_employee() {
    return this.m.employee;
  }
  set tmp_employee(val) {
    this.m.setEmployee({employee: val});
  }
}
