
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinWizard from '@/mixins/mixinWizard';
import WizardEmployeeinputBaseinfo from "@/components/wizard/employeeinput/Baseinfo.vue";
import WizardEmployeeinputAddressinfo from "@/components/wizard/employeeinput/Addressinfo.vue";
import CommonBigbutton from "@/components/common/Bigbutton.vue";

@Component({
  components: {
    WizardEmployeeinputBaseinfo,
    WizardEmployeeinputAddressinfo,
    CommonBigbutton,
  }
})
export default class Main extends Mixins(mixinWizard) {
  created() {
    if (this.global.wizard == 0) {
      this.change_page('wizard_config');
    } else if (this.global.wizard == 2) {
      this.change_page('shift', util.passquery['shift']);
    }
    this.m.setEmployee({employee: util.deep_copy(this.global.employee)});
  }

  update_wizard_employee() {
    this.m.updateEmployee();
  }

  get tmp_employee() {
    return this.m.employee;
  }
  set tmp_employee(val) {
    this.m.setEmployee({employee: val});
  }
}
