
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinWizard from '@/mixins/mixinWizard';
import global from "@/vuex/employee_each/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/employee_each/manager";
import header from "@/vuex/header";
import CommonZip from "@/components/common/Zip.vue";
import CommonProgress from "@/components/common/Progress.vue";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    CommonZip,
    CommonProgress,
    CommonText,
  }
})
export default class Main extends Mixins(mixinWizard) {
  reading_zip: boolean = false;

  get tmp_employee() {
    return this.m.employee;
  }

  set tmp_employee(val) {
    this.m.setEmployee({employee: val});
  }

  async input_zip(zip) {
    this.reading_zip = true;
    const address = await this.read_zip(zip);
    if (address !== null) this.tmp_employee.address = address;
    this.reading_zip = false;
  }
}
