import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import validate from "@/validations/validate";
import { validationWizardCompany } from "@/validations/company";
import { validationWizardEmployee } from "@/validations/employee.ts";
import dialog from "@/vuex/dialog";
import Vue from "vue";
import global from "./global";
import manager from "./manager";
import header from "@/vuex/header";
import Company from "@/typesold/company";
import VueRouter from "vue-router";
import router from "@/router";
import node from "@/vuex/node";

@Module({ dynamic: true, store, name: "wizard_manager", namespaced: true })
class Company_manager extends VuexModule {
  company: any = null;
  employee: any = null;

  @Mutation
  setCompany({ company }: { company: Company }) {
    Vue.set(this, "company", company);
  }

  @Mutation
  setEmployee({ employee }: { employee: Company }) {
    Vue.set(this, "employee", employee);
  }

  @Action({ rawError: true })
  public updateCompany() {
    if (validate(validationWizardCompany(this.company))) {
      dialog.openConfirmDialog({
        msg: "基本設定を確定してよろしいですか？",
        detail: "確定すると、今後変更することはできません。",
        cancel_button: "確定しない",
        exec_button: "確定する",
        func: this.updateCompanyConfirm,
        is_next_dialog: true,
        args: {},
      });
    }
  }
  @Action({ rawError: true })
  public updateCompanyConfirm({}) {
    dialog.openForceConfirmDialog({
      msg: "本当によろしいですか？",
      detail: "確定すると、今後変更することはできません。<br>よくご確認ください。",
      cancel_button: "確定しない",
      exec_button: "確定する",
      func: this.updateCompanyProcess,
      is_next_dialog: true,
      args: {},
    });
  }
  @Action({ rawError: true })
  public async updateCompanyProcess({}) {
    dialog.openProgressDialog({
      title: "処理中",
      msg: "この処理には時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    await util.post("company/update_wizard_company", { company: this.company });
    node.disable();
    router.push({ name: "shift", query: util.passquery["shift"] });
    dialog.openAlertDialog({ msg: "初期設定が完了しました", detail: "全機能をお使い頂けます。" });
  }

  @Action({ rawError: true })
  public updateEmployee() {
    if (validate(validationWizardEmployee(this.employee))) {
      dialog.openConfirmDialog({
        msg: "担当者情報を確定してよろしいですか？",
        detail: "後から社員ページで編集することも可能です。",
        cancel_button: "確定しない",
        exec_button: "確定する",
        func: this.updateEmployeeProcess,
        is_next_dialog: true,
        args: {},
      });
    }
  }
  @Action({ rawError: true })
  public async updateEmployeeProcess({}) {
    dialog.openProgressDialog({
      title: "処理中",
      msg: "この処理には時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    await util.post("employee_each/update_wizard_employee", { employee: this.employee });
    node.disable();
    router.push({ name: "shift", query: util.passquery["shift"] });
    dialog.openAlertDialog({ msg: "初期設定が完了しました", detail: "全機能をお使い頂けます。" });
  }
}

export default getModule(Company_manager);
